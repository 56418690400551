@media print {
    body * {
        visibility: hidden; /* Hide everything on the page */
    }
    .print-area * {
        visibility: visible; /* Show the print area */
        break-inside: avoid;
    }
}

@page {
    size: A4;
    margin: 0;
}